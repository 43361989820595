import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { REHYDRATE } from 'redux-persist'

import { setAuthenticated } from './account'
import { API_URL } from '../shared/constants'

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    let token = getState().account.token
    if (token) {
      headers.set('Authorization', token)
    }
    headers.set("Accept", "application/json")

    return headers
  }
})
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(setAuthenticated(false))
  }
  return result
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Account','ProviderAccount','Matchup','Wager','Bankroll'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath]
    }
  },
  endpoints: builder => ({
    // Account
    healthCheck: builder.query({
      query: () => '/status'
    }),
    login: builder.mutation({
      query: credentials => ({
        url: '/login',
        method: 'POST',
        body: credentials
      }),
      transformResponse(apiResponse, meta) {
        return { apiResponse, token: meta.response.headers.get('Authorization') }
      }
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'DELETE'
      }),
      invalidatesTags: ['Account','Matchup','Wager'],
    }),
    registerUser: builder.mutation({
      query: data => ({
        url: '/signup',
        method: 'POST',
        body: data,
      }),
      transformResponse(apiResponse, meta) {
        return { apiResponse, token: meta.response.headers.get('Authorization') }
      }
    }),
    getSnapshot: builder.query({
      query: () => '/snapshot'
    }),
    // UserProfiles
    updateUserProfile: builder.mutation({
      query: userProfile => ({
        url: `/user_profiles/${userProfile.id}`,
        method: 'PATCH',
        body: userProfile
      })
    }),
    // Available Players
    getAvailablePlayers: builder.query({
      query: params => ({ url: `/players?league=${params['league']}&date=${params['date']}` }),
      providesTags: ['Players'],
    }),
    // Wagers
    getWagers: builder.query({
      query: () => '/wagers',
      providesTags: ['Wager'],
    }),
    getWager: builder.query({
      query: wager_id => `/wagers/${wager_id}`,
      providesTags: ['Wager'],
    }),
    createWager: builder.mutation({
      query: wager => ({
        url: '/wagers',
        method: 'POST',
        body: wager,
      }),
      invalidatesTags: ['Wager'],
    }),
    getWagerResults: builder.query({
      query: () => '/wager_results',
      providesTags: ['WagerResult'],
    }),
    // WagerEntries
    getWagerEntries: builder.query({
      query: () => '/wager_entries',
      providesTags: ['WagerEntry'],
    }),
    getWagerEntry: builder.query({
      query: wager_entry_id => `/wager_entries/${wager_entry_id}`,
      providesTags: ['WagerEntry'],
    }),
    createWagerEntry: builder.mutation({
      query: wager_entry => ({
        url: '/wager_entries',
        method: 'POST',
        body: wager_entry,
      }),
      invalidatesTags: ['WagerEntry'],
    }),
    // Bankroll
    getClientToken: builder.query({
      query: () => '/bankroll/get_token',
    }),
    getBankroll: builder.query({
      query: () => '/bankroll',
      providesTags: ['Bankroll'],
    }),
    depositBankroll: builder.mutation({
      query: body => ({
        url: '/bankroll/deposit',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Bankroll'],
    }),
    // Support Requests
    createSupportRequest: builder.mutation({
      query: body => ({
        url: '/support_requests',
        method: 'POST',
        body: body,
      }),
    }),
    // FAQs
    getFaqs: builder.query({
      query: () => '/faqs',
    }),
  })
})

export const {
  useHealthCheckQuery,
  useLoginMutation, 
  useLogoutMutation,
  useRegisterUserMutation,
  useGetSnapshotQuery,
  useUpdateUserProfileMutation,
  useGetAvailablePlayersQuery, 
  useLazyGetAvailablePlayersQuery,
  useGetWagersQuery,
  useGetWagerQuery,
  useGetWagerEntryQuery,
  useGetWagerEntriesQuery,
  useCreateWagerMutation,
  useGetWagerResultsQuery,
  useCreateWagerEntryMutation,
  useGetBankrollQuery,
  useDepositBankrollMutation,
  useGetClientTokenQuery,
  useCreateSupportRequestMutation,
  useGetFaqsQuery,
} = apiSlice
