import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: { 
    modalVisible: false,
    currentPage: {
      component: false,
      props: {},
    },
    authCookies: { espn: null }
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    toggleModalVisible: (state) => {
      state.modalVisible = !state.modalVisible
    },
    setAuthCookies: (state, action) => {
      state.authCookies[action.payload.provider] = action.payload.cookies
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.logout.matchFulfilled,
      (state, { payload }) => {
        state.currentPage = {
          component: false,
          props: {},
        }
      }
    )
  },
})

export const selectCurrentPage = state => state.session.currentPage
export const selectModalVisible = state => state.session.modalVisible
export const selectAuthCookies = state => state.session.authCookies

export const { setCurrentPage, toggleModalVisible, setAuthCookies } = sessionSlice.actions

export default sessionSlice.reducer