import { useState } from 'react'
import { useSelector } from 'react-redux';
import { Stack, TextField, Button, Typography, Box } from '@mui/material'

import { useUpdateUserProfileMutation } from 'app/reducers/api'
import { selectCurrentUser } from 'app/reducers/account';

const AccountSettings = (props) => {
  const currentUser = useSelector(selectCurrentUser)
  const [updateUserProfile, { }] = useUpdateUserProfileMutation()
  const [name, setName] = useState(currentUser.name)

  const handleUpdateProfile = () => {
    if(name){
      try {
        // update profile call
        updateUserProfile({ id: currentUser.profileId, name: name })
      } catch(err) {
        console.error('could not update profile', err)
      }
    }
  }

  const renderProfileForm = () => {
    return (
      <Stack spacing={1}>
        <TextField label='Name' placeholder='Enter full name' value={name} variant="outlined" onChange={(e) => setName(e.target.value)} fullWidth required/>
        <Button onClick={handleUpdateProfile} color='primary' variant="contained" fullWidth>Update</Button>
      </Stack>
    )
  }

  return (
    <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
      <Typography variant='h6'>Profile</Typography>
      { renderProfileForm() }
    </Stack>
  )
}

export default AccountSettings
