import { Alert, AlertTitle, Stack, Typography, Accordion, AccordionSummary, AccordionDetails} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Loading } from 'app/components'
import { useGetFaqsQuery } from 'app/reducers/api'

const FaqsList = () => {
  const {
    data: faqs,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetFaqsQuery()

  const renderFaqs = () => {
    return (
      <>
      { faqs.map((faq, i) => {
        return (
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${i}-content`}
              id={`panel-${i}-header`}
            >
              <Typography>{ faq.question }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{ faq.answer }</Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}
     </>
    )
  }

  return (
    <Stack spacing={2} alignItems='center' justifyContent="center" sx={{ p: 3, pt: 0, width: '80%', m: 'auto'  }}>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          { error }
        </Alert>
      )}
      <Typography variant="h4" align="center">FAQs</Typography>
      { (isFetching || isLoading) ? <Loading message="Please wait while we fetch the FAQs..." /> : renderFaqs() }
    </Stack>
  )
}

export default FaqsList
