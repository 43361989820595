import { Typography, Stack, CircularProgress } from '@mui/material'

const Loading = (props) => {

  return (
    <Stack justifyContent="center" alignItems="center" spacing={1} sx={{ p: 3 }}>
      <CircularProgress />
      <Typography variant="h6" align="center">{ props.message }</Typography>
    </Stack>
  )
}

export default Loading