export const SIDEBET_COMMISSION = 0.12
export const CREATOR_COMMISSION = 0.02
export const PROCESSING_FEE = 0.05
export const API_URL = process.env.REACT_APP_SIDEBET_API_URL + '/api/v1'
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_API_CLIENT_ID
export const PAYPAL_SECRET = process.env.REACT_APP_PAYPAL_APP_SECRET
export const PROVIDERS = [
  {
    name: 'espn',
    displayName: 'ESPN',
    url: 'https://fantasy.espn.com',
    cookies: [ 'espn_s2', 'swid' ]
  }
]
