import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ButtonGroup, IconButton, InputLabel, MenuItem, FormControl, Select, Box, Stepper, Step, StepLabel, Divider, Stack, Card, CardActions, CardContent, CardMedia, Typography, Grid, Button } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LockClockIcon from '@mui/icons-material/LockClock'
import HandshakeIcon from '@mui/icons-material/Handshake'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'

import { useLazyGetAvailablePlayersQuery, useCreateWagerMutation } from 'app/reducers/api'
import matchupImage from 'app/assets/matchup20240517.png'
import { Loading } from 'app/components'

const ContestsNew = (props) => {
  const initialTeamsState = { "team1": {}, "team2": {} }
  const [teams, setTeams] = useState(initialTeamsState)
  const [selectedPlayers, setSelectedPlayers] = useState({})
  const [createWager, {}] = useCreateWagerMutation()
  const navigate = useNavigate()

  // Add a team
  const addTeam = (teamId) => {
    setTeams(prevTeams => ({
      ...prevTeams,
      [teamId]: {}
    }))
  }

  // Add a player to a specific team
  const addPlayer = (teamId, player) => {
    console.log("teams", Object.keys(teams))
    let newTeamId = teamId || Object.keys(teams)[0] || `team${Object.keys(teams).length + 1}`
    let playerId = teams[newTeamId] ? `player${Object.keys(teams[newTeamId]).length + 1}` : "player1"
    setTeams(prevTeams => ({
      ...prevTeams,
      [newTeamId]: {
        ...prevTeams[newTeamId],
        [playerId]: player
      }
    }))
    setSelectedPlayers(prevPlayers => ({
      ...prevPlayers,
      [player["player"]["id"]]: 1
    }))
  }

  // Remove a team
  const removeTeam = (teamId) => {
    setTeams(prevTeams => {
      const { [teamId]: removedTeam, ...remainingTeams } = prevTeams
      return remainingTeams
    })
  }

  // Remove a player from a specific team
  const removePlayer = (teamId, playerId) => {
    let player_key = teams[teamId][playerId]["player"]["id"]
    setTeams(prevTeams => ({
      ...prevTeams,
      [teamId]: Object.keys(prevTeams[teamId])
        .filter(id => id !== playerId)
        .reduce((result, key) => {
          result[key] = prevTeams[teamId][key];
          return result
        }, {})
    }))
    setSelectedPlayers(prevPlayers => ({
      ...prevPlayers,
      [player_key]: 0
    }))
  }

  const movePlayerUp = (teamId, playerId) => {
    let teamIds = Object.keys(teams)
    let teamIndex = teamIds.indexOf(teamId)
    if(teamIndex > 0){
      let player = teams[teamId][playerId]
      addPlayer(teamIds[teamIndex - 1], player)
      removePlayer(teamId, playerId)
    }
  }

  const movePlayerDown = (teamId, playerId) => {
    let teamIds = Object.keys(teams)
    let teamIndex = teamIds.indexOf(teamId)
    if(teamIndex < teamIds.length - 1){
      let player = teams[teamId][playerId]
      addPlayer(teamIds[teamIndex + 1], player)
      removePlayer(teamId, playerId)
    }
  }

  const [ selectedLeague, setSelectedLeague ] = useState("nfl")
  const [ selectedDate, setSelectedDate ] = useState(new Date())
  const [triggerGetAvailablePlayers, {
    data: availablePlayers,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  }] = useLazyGetAvailablePlayersQuery()

  const handleChangeLeague = (e) => {
    if(selectedLeague != e.target.value){
      setSelectedLeague(e.target.value)
      setTeams(initialTeamsState)
      setSelectedPlayers({})
    }
  }

  const handleChangeDate = (newDate) => {
    setSelectedDate(newDate)
  }

  const getActiveStep = () => {
    if (!selectedLeague){
      return 0
    } else if (!selectedDate){
      return 1
    } else if (Object.keys(teams).length < 2){
      return 2
    } else {
      return 3
    }
  }

  const fetchAvailablePlayers = () => {
    if(selectedLeague && selectedDate){
      triggerGetAvailablePlayers({ league: selectedLeague, date: dayjs(selectedDate).format('YYYY-MM-DD')})
    }
  }

  const handleResetContest = () => {
    setTeams(initialTeamsState)
    setSelectedPlayers({})
  }

  const handleCreateWager = async (event) => {
    event.preventDefault()
    let proposedWager = {}
    Object.keys(teams).forEach((teamId) => {
      let players = Object.keys(teams[teamId]).map((playerId) => {
        let player = teams[teamId][playerId]
        return (
          {
            team_id: player["team"]["id"],
            player_id: player["player"]["id"],
            game_id: player["game"]["id"]
          }
        )
      })
      proposedWager[teamId] = players
    })

    try {
      let response = await createWager({league: selectedLeague, contest_params: proposedWager}).unwrap()
      navigate(`/contests/${response.id}`)
    } catch(err) {
      console.error('could not place wager', err)
    }
  }


  useEffect(() => {
    fetchAvailablePlayers()
  }, [selectedLeague, selectedDate])

  const renderStepper = () => {
    const steps = [
      'Select League',
      'Select Date',
      'Add Players',
      'Create contest',
    ]

    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={getActiveStep()} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  const renderMatchups = () => {
    return (
      <>
      </>
    )
  }

  const entryA = {
    id: 1234,
  }
  const entryB = {
    id: 4321,
  }

  const renderActions = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>25 entries for $340</Typography>
            <Button color="success" variant="contained"
              component={Link} to='/contests/tail' state={{ entry: entryA }}>
              { "Place Entry" }
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Total Pool: $580</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>12 entries for $240</Typography>
            <Button color="success" variant="contained"
              component={Link} to='/contests/tail' state={{ entry: entryB }}>
              { "Place Entry" }
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  
  const renderCard = (player) => {
    const matchupInfo = "vs CWS Mon 7:00pm EST"
    return (
      <Card style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"}}>
        <CardContent>
          <Stack width="100%">
            <CardMedia
              component="img"
              image={player["team"]["logo"]}
              width="50%"
            />
            <Typography align='center'>{ `${player["player"]["position"]} | ${player["player"]["name"]}` }</Typography>
            <Typography variant='subtitle2' align='center' style={{ fontSize: '0.7rem' }}>{ player["game"]["date_display_short"]}</Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            { selectedPlayers[player["player"]["id"]] == 1 && (
              <CheckCircleIcon color="success" />
            )}
            { (!selectedPlayers[player["player"]["id"]] || selectedPlayers[player["player"]["id"]] == 0) && (
              <ButtonGroup variant="text" size="small" disableElevation>
                <Button onClick={() => addPlayer("team1", player)}>Team 1</Button>
                <Button onClick={() => addPlayer("team2", player)}>Team 2</Button>
              </ButtonGroup>
            )}
          </Box>
        </CardActions>
      </Card>
    )
  }

  const renderPlayerSelection = () => {
    return (
      <Box display="flex" justifyContent="center">
        { selectedLeague && selectedDate && availablePlayers && availablePlayers.length > 0 ? (
          <>
          <Grid container spacing={2} display="flex">
            { availablePlayers.map((player, i) => {
              return (
                <Grid item xs={6} sm={4} md={3} key={i}>
                  { renderCard(player) }
                </Grid>
              )
            })}
          </Grid>
          </>
        ) : (
          <Typography variant="subtitle2">
            { (availablePlayers && availablePlayers.length == 0) ? "No players available for this league and date." : "Select a league and date first to see available players." }
          </Typography>
        )}
      </Box>
    )
  }

  const renderContestBuilder = () => {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="overline">Current Contest</Typography>
            { false && <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addTeam(`team${Object.keys(teams).length + 1}`)}>Add Team</Button> }
            { Object.keys(teams).map((teamId, i) => {
              return (
                <Stack spacing={1} key={teamId}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">{ `Team ${i+1}` }</Typography>
                    { Object.keys(teams).length > 2 && (
                      <IconButton onClick={() => removeTeam(teamId)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Typography variant="caption">{ `${Object.keys(teams[teamId]).length} players selected` }</Typography>
                  { Object.keys(teams[teamId]).length == 0 && <Typography variant="subtitle2" textAlign="center">Add players to this team or delete the team.</Typography>}
                  { Object.keys(teams[teamId]).map((playerId, j) => {
                    return (
                      <Card key={`${teamId}_${playerId}`}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <Stack>
                              <IconButton disabled={i == 0} onClick={() => movePlayerUp(teamId, playerId)}>
                                <KeyboardArrowUpIcon />
                              </IconButton>
                              <IconButton disabled={i == Object.keys(teams).length - 1} onClick={() => movePlayerDown(teamId, playerId)}>
                                <KeyboardArrowDownIcon />
                              </IconButton>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <CardMedia
                                component="img"
                                image={teams[teamId][playerId]["team"]["logo"]}
                                width="25%"
                              />
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                              <Stack>
                                <Typography align='center'>{ `${teams[teamId][playerId]["player"]["position"]} | ${teams[teamId][playerId]["player"]["name"]}` }</Typography>
                                <Typography variant='subtitle2' align='center' style={{ fontSize: '0.7rem' }}>{ teams[teamId][playerId]["game"]["date_display_short"] }</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <IconButton onClick={() => removePlayer(teamId, playerId)}>
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Stack>
              )
            })}
          </Stack>
          <Divider />
        </CardContent>
        <CardActions display='flex'> 
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} display='flex' justifyContent='center'>
              <Button variant="contained" color="success" onClick={handleCreateWager}>Create Contest</Button>
            </Grid>
            <Grid item xs={12} sm={6} display='flex' justifyContent='center'>
              <Button variant="outlined" onClick={handleResetContest}>Reset</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }

  const renderLeagueSelection = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 80, width: "100%" }}>
        <InputLabel>League</InputLabel>
        <Select
          value={selectedLeague}
          onChange={handleChangeLeague}
          autoWidth
          label="League"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="nfl">NFL</MenuItem>
          <MenuItem value="mlb">MLB</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const renderDateSelection = () => {
//minDate={dayjs(new Date())} />
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl sx={{ m: 1, minWidth: 80, width: "100%" }}>
          <DatePicker label="Date" defaultValue={dayjs(selectedDate)} onChange={handleChangeDate} />
        </FormControl>
      </LocalizationProvider>
    )
  }

  const renderSelectors = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>{ renderLeagueSelection() }</Grid>
        <Grid item xs={6}>{ renderDateSelection() }</Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        { renderStepper() }
        { renderSelectors() }
        { renderPlayerSelection() }
      </Grid>
      <Grid item xs={12} sm={4}>
        { renderContestBuilder() }
      </Grid>
    </Grid>
  )
};

export default ContestsNew;
