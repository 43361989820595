import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import { PAYPAL_CLIENT_ID } from 'app/shared/constants'
import Routes from 'app/routes'
import Layout  from 'app/layout'

const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#246EB9',
    },
    secondary: {
      main: '#FDFFFC',
    },
  },
})

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={appTheme}>
        <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>
          <CssBaseline />
          <Layout>
            <Routes />
          </Layout>
        </PayPalScriptProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
