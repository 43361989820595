import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Grid, Button, Divider, Paper, Alert, AlertTitle, ListSubheader, List, ListItem, ListItemButton, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableHead, TableCell, ToggleButtonGroup, ToggleButton } from '@mui/material'

import { Loading } from 'app/components'
import { ContestEntriesShow } from 'app/pages/contest_entries'
import { useGetWagerResultsQuery } from 'app/reducers/api'
import { formatCurrency } from 'app/shared/utils'

const ContestEntryResultsList = () => {
  const [filter, setFilter] = useState('all')
  const {
    data: contestEntryResults,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetWagerResultsQuery()

  useEffect(() => {
    refetch()
  },[])

  const renderContestEntryResults = () => {
    const tableCellStyle = {
      width: 90,
      maxWidth: 90,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: 'center',
    }

    return (
      <Paper elevation={1} sx={{ p: 2 }}>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="wagers table header">
            <TableHead>
              <TableRow>
                <TableCell sx={tableCellStyle}>Contest</TableCell>
                <TableCell sx={tableCellStyle}>Your Pick</TableCell>
                <TableCell sx={tableCellStyle}>Amount Won/Lost</TableCell>
                <TableCell sx={tableCellStyle}>Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { contestEntryResults && contestEntryResults.map((contestEntryResult) => {
                const entryWonLossAmount = formatCurrency(contestEntryResult.amount_won_lost)
                return (
                  <TableRow key={`row-${contestEntryResult.id}`}>
                    <TableCell sx={tableCellStyle}>{ contestEntryResult.wager["contest_name"] }</TableCell>
                    <TableCell sx={tableCellStyle}>{ `Team ${ Object.keys(contestEntryResult.wager.teams).indexOf(contestEntryResult.selected_option) + 1 }` }</TableCell>
                    <TableCell sx={tableCellStyle}>{ entryWonLossAmount }</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <Button color="success" variant="contained" size="small" component={Link} to={`/contest_entry_results/${contestEntryResult.id}`}>
                        { 'View' }
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  return (
    <>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
        </Alert>
      )}
      <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
        <Typography variant='h6'>My Entry Results</Typography>
        { (isFetching || isLoading) ? <Loading message="One second while we fetch all of your contest entry results..." /> : renderContestEntryResults() }
      </Stack>
    </>
  )
};

export default ContestEntryResultsList
