import { useState, useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { MuiMarkdown } from 'mui-markdown'

const Terms = () => {
  const [copy, setCopy] = useState('');

  useEffect(() => {
    import('./terms.md')
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setCopy(res))
          .catch(err => console.log(err));
        })
      .catch(err => console.log(err));
  });

  return (
    <Stack spacing={2} alignItems='center' justifyContent="center" sx={{ p: 3, pt: 0, width: '80%', m: 'auto'  }}>
      <MuiMarkdown>{ copy }</MuiMarkdown>
    </Stack>
  )
}

export default Terms
