import React, {useEffect, useState, useRef } from 'react'
import { Button } from '@mui/material'
import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js'
import { useNavigate } from 'react-router-dom'

import { PROCESSING_FEE } from 'app/shared/constants'
import { useDepositBankrollMutation } from 'app/reducers/api'
import { currencyStringToCents } from 'app/shared/utils'

const PaypalDropIn = (props) => {
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [depositBankroll, {}] = useDepositBankrollMutation()
  const navigate = useNavigate()
  
  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "Deposit",
          amount: {
            currency_code: "USD",
            value: parseFloat(props.depositAmount) + (parseFloat(props.depositAmount) * PROCESSING_FEE)
          }
        }
      ]
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    })
  }

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
      depositBankroll({ amount: currencyStringToCents(props.depositAmount), details: details })
      navigate('/account/bankroll')
    })
  }

  return (
    <div style={{ display: `${props.show ? "block" : "none"}` }}>
      <PayPalButtons forceReRender={[props]}createOrder={createOrder} onApprove={onApprove} fundingSource={FUNDING.CARD} />
    </div>
  )
}

export default PaypalDropIn
