import { useRoutes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { AuthedComponent } from 'app/components'
import { AccountLogin, AccountSettings, AccountRegister, AccountBankroll } from 'app/pages/account'
import { ContestsNew, ContestsShow, ContestsList } from 'app/pages/contests'
import { ContestEntriesShow, ContestEntriesList } from 'app/pages/contest_entries'
import { ContestEntryResultsShow, ContestEntryResultsList } from 'app/pages/contest_entry_results'
import { default as SupportRequestsNew } from 'app/pages/support_requests'
import { default as FaqsList } from 'app/pages/faqs'
import { default as Terms } from 'app/pages/terms'

const Routes = () => {
  let routes = useRoutes([
    { path: '/', element: <AuthedComponent component={ContestsList} />},
    { path: '/contests', element: <AuthedComponent component={ContestsList} /> },
    { path: '/contests/new', element: <AuthedComponent component={ContestsNew} />},
    { path: '/contests/:contest_id', element: <AuthedComponent component={ContestsShow} />},
    { path: '/contest_entries', element: <AuthedComponent component={ContestEntriesList} />},
    { path: '/contest_entries/:contest_entry_id', element: <AuthedComponent component={ContestEntriesShow} />},
    { path: '/contest_entry_results/', element: <AuthedComponent component={ContestEntryResultsList} />},
    { path: '/contest_entry_results/:contest_entry_result_id', element: <AuthedComponent component={ContestEntryResultsShow} />},
    { path: '/account/login', element: <AccountLogin /> },
    { path: '/account/register', element: <AccountRegister /> },
    { path: '/account/settings', element: <AuthedComponent component={AccountSettings} /> },
    { path: '/account/bankroll', element: <AuthedComponent component={AccountBankroll} /> },
    { path: '/support', element: <AuthedComponent component={SupportRequestsNew} /> },
    { path: '/faq', element: <FaqsList /> },
    { path: '/terms', element: <Terms /> },
    { path: '*', element: <Navigate to='/' /> }
  ])

  return routes
}

export default Routes
