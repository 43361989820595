import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Link, Paper, Alert, AlertTitle, Divider, Box, IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ToggleButtonGroup, ToggleButton, CardMedia, Grid, Typography, ButtonGroup, Stack, Chip } from '@mui/material'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useGetWagerEntryQuery } from 'app/reducers/api'
import { Loading, Snapshot } from 'app/components'
import { formatCurrency } from 'app/shared/utils'

const ContestEntriesShow = ({ contest_entry_id: contest_entry_id }) => {
  const {
    data: contestEntry,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetWagerEntryQuery(contest_entry_id)

  useEffect(() => {
    refetch()
  }, [])


  const wagerAmountDisplay = () => {
    return formatCurrency(contestEntry["amount"])
  }

  const renderContestDetails = () => {
    let wager = contestEntry && contestEntry["wager"]
    let selectedTeam = contestEntry && contestEntry["selected_option"]
    return (
      <Stack spacing={2} direction="row">
        { wager && wager.teams && wager.contest_stats && Object.keys(wager.teams).map((teamId, i) => {
          return (
            <Card spacing={1} key={teamId} style={{ border: `${selectedTeam == teamId ? '1px solid green' : 'none' }` }} disableElevation>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                  { selectedTeam && selectedTeam == teamId ? <CheckCircleIcon color="success" /> : <RadioButtonUncheckedIcon /> }
                  </Grid>
                  <Grid item xs={6}>
                    { wager.contest_stats && wager.contest_stats["teams"][teamId] && (
                      <>
                      <Typography variant="subtitle2">{ `Total: ${ formatCurrency(wager.contest_stats["teams"][teamId]["total"]) }` }</Typography>
                      <Typography variant="subtitle2">{ `Odds: ${ wager.contest_stats["teams"][teamId]["odds_display"] || 'N/A' }` }</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6">{ `Team ${i+1}` }</Typography>
                  { Object.keys(wager.teams[teamId]).map((playerId, j) => {
                    return (
                      <Card key={`${teamId}_${playerId}`}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                              <CardMedia
                                component="img"
                                image={wager.teams[teamId][playerId]["team"]["logo"]}
                                width="25%"
                              />
                            </Grid>
                            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                              <Stack>
                                <Typography align='center'>{ `${wager.teams[teamId][playerId]["player"]["position"]} | ${wager.teams[teamId][playerId]["player"]["name"]}` }</Typography>
                                <Typography variant='subtitle2' align='center' style={{ fontSize: '0.7rem' }}>{ wager.teams[teamId][playerId]["game"]["date_display_short"] }</Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Stack>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>
          )
        })}
      </Stack>
    )
  }

  const renderContestEntryDetails = () => {
    let wager = contestEntry && contestEntry.wager
    
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Battle of the Barrels!</Typography>
          <Typography variant="caption">by tampahawk900</Typography>
          <Typography variant="subtitle2">{ `Pool Size: ${wager && formatCurrency(wager.contest_stats.total)}` }</Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Typography variant="h4">{ `Your Pick: Team ${wager && Object.keys(wager.teams).indexOf(contestEntry.selected_option) + 1} for ${formatCurrency(contestEntry.amount)}` }</Typography>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const renderContestEntry = () => {
    return (
      <Stack spacing={2}>
        { renderContestEntryDetails() }
        { renderContestDetails() }
      </Stack>
    )
  }

  return (
    <>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
        </Alert>
      )}
      { (isFetching || isLoading) ? <Loading message="One sec while we load your contest entry..." /> : renderContestEntry() }
    </>
  )
};

export default ContestEntriesShow
