import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Paper, Stack, Typography, Box, Grid, Button, Alert, AlertTitle, Collapse, IconButton, TableContainer, TableHead, TableBody, Table, TableCell, TableRow } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import { PayPalButtons } from '@paypal/react-paypal-js'

import { PaypalDropIn, BraintreeDropIn, Loading } from 'app/components';
import { useGetBankrollQuery, useGetClientTokenQuery } from 'app/reducers/api';
import { selectCurrentUser } from 'app/reducers/account';
import { formatCurrency, currencyStringToCents } from 'app/shared/utils';
import { PROCESSING_FEE } from 'app/shared/constants'

const AccountBankroll = (props) => {
  const { data: clientToken } = useGetClientTokenQuery()
  const {
    data: bankroll,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetBankrollQuery()
  const currentUser = useSelector(selectCurrentUser)
  const [ depositAmount, setDepositAmount ] = useState(0)
  const [ showPaypalButtons, setShowPaypalButtons ] = useState(false)
  const [ depositSuccess, setDepositSuccess] = useState(false)

  useEffect(() => {
    refetch()
  }, [])

  const renderDetails = () => {

    const loadingMessage = <Loading message="One second while we fetch your bankroll..." />
    const renderBalance = () => {
      return (
        <>
        <Grid item xs={4} justifyContent='center' alignItems='center'>
          <Typography variant='subtitle1' align='center'>
            <strong>Balance</strong>
            <Typography>{ formatCurrency(bankroll['balance']) }</Typography>
          </Typography>
        </Grid>
        <Grid item xs={4} justifyContent='center' alignItems='center'>
        <Typography variant='subtitle1' align='center'>
          <strong>Available</strong>
          <Typography sx={{ color: 'success.main' }}>{ formatCurrency(bankroll['available']) }</Typography>
        </Typography>
        </Grid>
        <Grid item xs={4} justifyContent='center' alignItems='center'>
        <Typography variant='subtitle1' align='center'>
          <strong>Pending</strong>
          <Typography sx={{ color: 'warning.main' }}>{ formatCurrency(bankroll['pending']) }</Typography>
        </Typography>
        </Grid>
        </>
      )
    }
    return (
      <Grid container component={Paper} sx={{ p: 3 }}>
        { (isFetching || isLoading) ? loadingMessage : renderBalance() }
      </Grid>
    )
  }

  const handleSuccessfulDeposit = () => {
    refetch()
    setShowPaypalButtons(false)
    setDepositAmount(0)
    setDepositSuccess(true)
    setTimeout(() => {
      setDepositSuccess(false);
    }, 5000);
  }

  const renderDepositForm = () => {
    const renderChargeAmount = () => {
      let deposit = currencyStringToCents(depositAmount)
      let fee = deposit * PROCESSING_FEE  
      let chargeAmount = formatCurrency(deposit + fee)

      return (
        <Typography variant='caption'>{ `Your card will be charged a 5% processing fee for a total of ${chargeAmount}` }</Typography>
      )
    }
    return (
      <Stack spacing={1} textAlign="center" component={Paper} sx={{ p: 3 }} fullWidth>
        <Typography variant='subtitle1'>Deposit Funds</Typography>
        <CurrencyTextField
          label="Amount"
          variant="standard"
          value={depositAmount}
          currencySymbol="$"
          minimumValue="0"
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(event, value)=> setDepositAmount(value)}
          />
        { parseFloat(depositAmount) > 0 && renderChargeAmount() }
        <Button onClick={() => setShowPaypalButtons(!showPaypalButtons) } disabled={parseFloat(depositAmount) <= 0} variant="contained" color="success">Deposit</Button>
        <PaypalDropIn show={showPaypalButtons} depositAmount={depositAmount} />
      </Stack>
    )
  }

  const renderTransactions = () => {
    return (
      <Stack component={Paper} spacing={1} textAlign="center" sx={{ p: 3 }}>
        <Typography variant='subtitle1'>Transactions</Typography>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="matchups table">
            <TableHead>
              <TableRow key='bankroll-transaction-header'>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Info</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { bankroll && bankroll['bankroll_transactions'].map((transaction) => {
                return (
                  <TableRow key={ `transaction_${transaction['id']}` }>
                    <TableCell>{ transaction['created_at'] }</TableCell>
                    <TableCell>{ transaction['transaction_type'] }</TableCell>
                    <TableCell>{ transaction['transaction_info'] }</TableCell>
                    <TableCell>{ (transaction['amount'] / 100.0).toLocaleString("en-US", { style: "currency", currency: "USD"}) }</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    )
  }

  return (
    <>
    { error && (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        { error }
      </Alert>
    )}
    <Collapse in={depositSuccess} timeout={{ enter: 300, exit: 1000 }}>
      <Alert severity="success"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setDepositSuccess(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>Success!</AlertTitle>
        Successfully made deposit! Give us a minute to update your bankroll.
      </Alert>
    </Collapse>
    <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
      <Typography variant='h6'>Bankroll</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>{ renderDetails() }</Grid>
        <Grid item xs={12} sm={6}>{ renderDepositForm() }</Grid>
        <Grid item xs={12} sm={6}>{ renderTransactions() }</Grid>
      </Grid>
    </Stack>
    </>
  )
}

export default AccountBankroll
