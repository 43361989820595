import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import accountSlice from './reducers/account'
import sessionSlice from './reducers/session'
import { apiSlice } from './reducers/api'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  account: accountSlice,
  session: sessionSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware(),
      apiSlice.middleware
    ]
  }
})

export const persistor = persistStore(store)