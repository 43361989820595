import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, IconButton, Drawer, ListItem, ListItemText, ListItemButton, Link, Stack, Typography, Divider, List, ListItemIcon } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import CircleIcon from '@mui/icons-material/Circle';
import HexagonIcon from '@mui/icons-material/Hexagon';
import SquareIcon from '@mui/icons-material/Square';
import PentagonIcon from '@mui/icons-material/Pentagon';
import PaidIcon from '@mui/icons-material/Paid';

import logo from 'app/assets/logo-v0.png'
import { Snapshot, SideMenu } from 'app/components'
import { selectAuthenticated } from 'app/reducers/account';
import { useLogoutMutation } from 'app/reducers/api';

const menuItems = {
  topSection: {
    header: null,
    menuItems: [
      {
        icon: <PaidIcon fontSize='small' color='success' />,
        text: "View Contests",
        path: '/',
        active: true
      },
      {
        icon: <HexagonIcon fontSize='small' />,
        text: "My Entries",
        path: '/contest_entries',
        active: true
      },
      {
        icon: <CircleIcon fontSize='small' />,
        text: "My Results",
        path: '/contest_entry_results/',
        active: true
      },
    ]
  },
  bankrollSection: {
    header: "Bankroll",
    menuItems: [
      {
        icon: <CircleIcon fontSize='small' />,
        text: "Manage",
        path: '/account/bankroll',
        active: true
      },
      {
        icon: <HexagonIcon fontSize='small' />,
        text: "Add/Withdraw Funds",
        path: '/account/bankroll',
        active: true
      },
      {
        icon: <SquareIcon fontSize='small' />,
        text: "History",
        path: '/account/bankroll',
        active: false
      },
      {
        icon: <PentagonIcon fontSize='small' />,
        text: "Tax Information and Forms",
        path: '/account/bankroll',
        active: false
      }
    ]
  },
  resourcesSection: {
    header: "Resources",
    menuItems: [
      {
        icon: <CircleIcon fontSize='small' />,
        text: "FAQ",
        path: '/faq',
        active: true
      },
      {
        icon: <SquareIcon fontSize='small' />,
        text: "Terms & Privacy Policy",
        path: '/terms',
        active: true
      },
      {
        icon: <HexagonIcon fontSize='small' />,
        text: "Support",
        path: '/support',
        active: true
      }
    ]
  },
  accountSection: {
    header: null,
    menuItems: [
      {
        icon: null,
        text: "Settings",
        path: '/account/settings',
        active: true
      }
    ]
  }
}

const sideBarWidth = 240

const Layout = (props) => {
  const authenticated = useSelector(selectAuthenticated)
  const [mobileOpen, setMobileOpen] = useState(false);

  const [logout, {}] = useLogoutMutation()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/account/login')
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderWelcomeText = () => {
    return (
      <Stack spacing={4} sx={{ backgroundColor: 'primary.light', height: '100%', p: 3  }} alignItems='center' justifyContent='center'>
        <img src={logo} style={{ width: 200, maxWidth: '100%', maxHeight: '100%' }}/>
        <Typography variant='h6' textAlign='center'>Up the stakes in your existing fantasy leagues by competing for cash prizes against against any team in your league regardless of who you are actually playing this week!</Typography>
        <Stack spacing={1}>
          <Typography variant='h6'><strong>Step 1.</strong> Create an account</Typography>
          <Typography variant='h6'><strong>Step 2.</strong> Compete in contests</Typography>
          <Typography variant='h6'><strong>Step 3.</strong> Win cash prizes!</Typography>
        </Stack>
      </Stack>
    )
  }

  const renderMenuItems = () => {
    return (
      <>
      <Toolbar>
        <Link to='/'><img src={logo} style={{ maxWidth: '100%', maxHeight: '100%' }}/></Link>
      </Toolbar>
      <List>
        { Object.keys(menuItems).map((menuSection, i) => {
          const menuItem = menuItems[menuSection]
          return (
            <>
            <Divider />
            { menuItem.header && <ListItem key={`menuItem-${i}`} disabled={true}><ListItemText primary={ menuItem.header } /></ListItem> }
            { menuItem.menuItems.map((subItem, j) => {
              if(subItem.active){
                return (
                  <ListItem key={`menuItem-${j}`} disablePadding>
                    <ListItemButton component={Link} to={subItem.path}>
                      { subItem.icon && <ListItemIcon>{ subItem.icon }</ListItemIcon> }
                      <ListItemText primary={subItem.text} />
                    </ListItemButton>
                  </ListItem>
                )
              }
            })}
            </>
          )
        })}
        <ListItem key='menuItem-logout' disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      </List>
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${sideBarWidth}px)` },
          ml: { sm: `${sideBarWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: 'secondary.main', display: { sm: 'none' } }}>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={logo} style={{ width: 200, maxWidth: '100%', maxHeight: '100%' }}/>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: sideBarWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: sideBarWidth },
          }}
        >
          { authenticated ? renderMenuItems() : renderWelcomeText() }
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: sideBarWidth },
          }}
          open
        >
          { authenticated ? renderMenuItems() : renderWelcomeText() }
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 1, pt: 3, width: { xs: '100%', sm: `calc(100% - ${sideBarWidth}px)` } }}
      >
        <Toolbar sx={{ display: { sm: 'none' } }} />
        { props.children }
      </Box>
    </Box>
  )
}

export default connect()(Layout)
