import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Alert, AlertTitle, Stack, TextField, Button, Typography, Box, Divider } from '@mui/material'

import logo from 'app/assets/logo-v0.png'
import { useLoginMutation, useLogoutMutation } from 'app/reducers/api'
import { setAuthenticated } from 'app/reducers/account'

const AccountLogin = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const [login, {data: loginResponse, error: loginError}] = useLoginMutation()
  const [logout, {data: logoutResponse, error: logoutError}] = useLogoutMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit  = async (event) => {
    event.preventDefault();
    try {
      await logout()
      await login({ user: { email: email, password: password } }).unwrap()

      dispatch(setAuthenticated(true))
      navigate('/')
    } catch(err) {
      console.error('could not login', err)
      setError(err.data.error)
    }
  }

  const renderLogin = () => {
    return (
      <>
        <Typography variant="h4" align="center">Sign In</Typography>
        <TextField label='Email' placeholder='Enter email' variant="outlined" onChange={(e) => setEmail(e.target.value)} fullWidth required/>
        <TextField label='Password' placeholder='Enter password' type='password' variant="outlined" onChange={(e) => setPassword(e.target.value)} fullWidth required/>
        <Button type="submit" color='primary' variant="contained" onClick={handleSubmit} fullWidth>Sign in</Button>
        <Button variant="outlined" component={Link} to="/account/register" fullWidth>Create Account</Button>
      </>
    )
  }

  return (
    <Stack spacing={1} justifyContent='center' alignItems='center' sx={{ width: '600px', margin: 'auto', mt: '40px' }}>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          { error }
        </Alert>
      )}
      { renderLogin() }
    </Stack>
  )
}

export default AccountLogin
