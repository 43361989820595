import { useState } from 'react'
import { Alert, AlertTitle, Stack, Typography, TextField, Button } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { useCreateSupportRequestMutation } from 'app/reducers/api'

const SupportRequestsNew = () => {
  const [subject, setSubject] = useState('General')
  const [body, setBody] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')

  const [createSupportRequest, {}] = useCreateSupportRequestMutation()


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createSupportRequest({ support_request: { subject: subject, body: body } }).unwrap()
      setSubmitted(true)
    } catch(err) {
      console.error('could not submit support request', err)
      setError(err.data.error)
    }
  }

  const renderSubmittedMessage = () => {
    return (
      <>
        <CheckCircleOutlineIcon color='success' sx={{ fontSize: 40 }} />
        <Typography variant="h6" align="center">Your support request has been successfully submitted! We will get back to you as soon as we can.</Typography>
      <Button color='primary' variant="outlined" onClick={() => setSubmitted(false)} fullWidth>Need more help?</Button>
      </>
    )
  }
  const renderSupportForm = () => {
    return (
      <>
      <Typography variant="h4" align="center">Get Help</Typography>
      <TextField label='Message' placeholder='What can we help you with today?' variant="outlined" onChange={(e) => setBody(e.target.value)} multiline rows={10} fullWidth required/>
      <Button color='primary' variant="contained" onClick={handleSubmit} fullWidth>Submit</Button>
      </>
    )
  }

  return (
    <Stack spacing={2} alignItems='center' justifyContent="center" sx={{ p: 3, pt: 0, width: '80%', m: 'auto'  }}>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          { error }
        </Alert>
      )}
      { submitted ? renderSubmittedMessage() : renderSupportForm() }
    </Stack>
  )
}

export default SupportRequestsNew
