import { useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { selectAuthenticated } from "app/reducers/account"
import { useEffect } from 'react'

const AuthedComponent = (props) => {
  const authenticated = useSelector(selectAuthenticated)
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if(!authenticated){
      navigate('/account/login')
    }
  })

  let componentProps = { ...params, ...(location.state || {}) }

  return <props.component {...componentProps} />
}

export default AuthedComponent