import { createSlice } from '@reduxjs/toolkit'
import { apiSlice  } from './api'

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    currentUser: {},
    authenticated: false,
    token: null
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.currentUser = {
          id: payload.apiResponse.id,
          email: payload.apiResponse.email,
          profileId: payload.apiResponse.user_profile.id,
          name: payload.apiResponse.user_profile.name
        }
        state.token = payload.token
        state.authenticated = true
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.registerUser.matchFulfilled,
      (state, { payload }) => {
        state.currentUser = {
          id: payload.apiResponse.id,
          email: payload.apiResponse.email,
          profileId: payload.apiResponse.user_profile.id,
          name: payload.apiResponse.user_profile.name
        }
        state.token = payload.token
        state.authenticated = true
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.logout.matchFulfilled,
      (state, { payload }) => {
        state.currentUser = {}
        state.token = null
        state.authenticated = false
      }
    )

    builder.addMatcher(
      apiSlice.endpoints.updateUserProfile.matchFulfilled,
      (state, { payload }) => {
        state.currentUser = { ...state.currentUser, name: payload.name }
      }
    )
  },
})

export const selectAuthenticated = state => state.account.authenticated
export const selectToken = state => state.account.token
export const selectCurrentUser = state => state.account.currentUser
export const { setAuthenticated } = accountSlice.actions

export default accountSlice.reducer