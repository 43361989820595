import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Grid, Button, Divider, Paper, Alert, AlertTitle, ListSubheader, List, ListItem, ListItemButton, Typography, Stack, TableContainer, Table, TableBody, TableRow, TableHead, TableCell, ToggleButtonGroup, ToggleButton } from '@mui/material'

import { Loading } from 'app/components'
import { ContestEntriesShow } from 'app/pages/contest_entries'
import { useGetWagerEntriesQuery } from 'app/reducers/api'
import { formatCurrency } from 'app/shared/utils'

const ContestEntriesList = () => {
  const [filter, setFilter] = useState('all')
  const {
    data: contestEntries,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
    isFetching
  } = useGetWagerEntriesQuery()

  useEffect(() => {
    refetch()
  },[])

  const renderContestEntries = () => {
    const tableCellStyle = {
      width: 90,
      maxWidth: 90,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: 'center',
    }

    return (
      <Paper elevation={1} sx={{ p: 2 }}>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="wagers table header">
            <TableHead>
              <TableRow>
                <TableCell sx={tableCellStyle}>Contest</TableCell>
                <TableCell sx={tableCellStyle}>Your Pick</TableCell>
                <TableCell sx={tableCellStyle}>Entry Amount</TableCell>
                <TableCell sx={tableCellStyle}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { contestEntries && contestEntries.map((contestEntry) => {
                const entryAmount = formatCurrency(contestEntry.amount)
                return (
                  <TableRow key={`row-${contestEntry.id}`}>
                    <TableCell sx={tableCellStyle}>{ contestEntry.wager["contest_name"] }</TableCell>
                    <TableCell sx={tableCellStyle}>{ `Team ${ Object.keys(contestEntry.wager.teams).indexOf(contestEntry.selected_option) + 1 }` }</TableCell>
                    <TableCell sx={tableCellStyle}>{ entryAmount }</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <Button color="success" variant="contained" size="small" component={Link} to={`/contest_entries/${contestEntry.id}`}>
                        { 'View' }
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  return (
    <>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
        </Alert>
      )}
      <Stack spacing={2} sx={{ p: 3, pt: 0 }}>
        <Typography variant='h6'>My Entries</Typography>
        { (isFetching || isLoading) ? <Loading message="One second while we fetch all of the contest entries..." /> : renderContestEntries() }
      </Stack>
    </>
  )
};

export default ContestEntriesList
