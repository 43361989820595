import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Alert, AlertTitle, Stack, TextField, Button, FormControlLabel, FormControl, Checkbox, Typography, Box, CircularProgress } from '@mui/material'

import logo from 'app/assets/logo-v0.png'
import { useRegisterUserMutation } from 'app/reducers/api'
import { setAuthenticated } from 'app/reducers/account'

const AccountRegister = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [error, setError] = useState('')
  const [termsAgree, setTermsAgree] = useState(false)
  const [ageStateAgree, setAgeStateAgree] = useState(false)

  const [registerUser, { }] = useRegisterUserMutation()

  const handleSubmit  = async (event) => {
    event.preventDefault();
    if(password != passwordConfirmation){
      setError("Passwords must match.")
    } else {
      try {
        await registerUser({ user: { name: name, email: email, password: password, password_confirmation: passwordConfirmation } }).unwrap()
        dispatch(setAuthenticated(true))
        navigate('/')
      } catch(err) {
        console.error('could not register', err)
        setError(err.data.error)
      }
    }
  }

  const renderRegister = () => {
    return (
      <>
      <Typography variant="h4" align="center">
        Sign Up
      </Typography>
      <TextField label='Name' placeholder='Enter full name' variant="outlined" onChange={(e) => setName(e.target.value)} fullWidth required/>
      <TextField label='Email' placeholder='Enter email' variant="outlined" onChange={(e) => setEmail(e.target.value)} fullWidth required/>
      <TextField label='Password' placeholder='Enter password' type='password' variant="outlined" onChange={(e) => setPassword(e.target.value)} fullWidth required/>
      <TextField label='Confirm Password' placeholder='Re-enter password' type='password' variant="outlined" onChange={(e) => setPasswordConfirmation(e.target.value)} fullWidth required/>
      <FormControl
        error={termsAgree}
        component="fieldset"
        variant="standard"
        sx={{ alignItems: "center" }}
        required>
        <FormControlLabel control={<Checkbox checked={termsAgree} />} 
          label={ <Typography>I agree to the <Link to='/terms' target='_blank'>Terms and Privacy Policy.</Link></Typography> }
          onChange={(_, checked) => setTermsAgree(checked)} />
      </FormControl>
      <FormControl
        error={ageStateAgree}
        component="fieldset"
        variant="standard"
        sx={{ alignItems: "center" }}
        required>
        <FormControlLabel control={<Checkbox checked={ageStateAgree} />} label="I confirm that I am over 21 and reside in a permitted play state."
          onChange={(_, checked) => setAgeStateAgree(checked)} />
      </FormControl>
      <Button color='primary' disabled={ !(termsAgree && ageStateAgree) }variant="contained" onClick={handleSubmit} fullWidth>Sign up</Button>
      <Button variant="outlined" component={Link} to='/account/login' fullWidth>Back to Login</Button>
      </>
    )
  }

  return (
    <Stack spacing={1} justifyContent='center' alignItems='center' sx={{ width: '600px', margin: 'auto', mt: '40px' }}>
      { error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          { error }
        </Alert>
      )}
      { renderRegister() }
    </Stack>
  )
}

export default AccountRegister
